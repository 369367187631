import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { icons } from 'src/assets'
import { toast } from 'react-toastify'
import {
  Box,
  LabeledText,
  Button as ModalButton,
  Typography,
  Grid,
  SelectInput,
  TextFieldLabel,
  IconButton,
} from 'src/components/UI'
import { useIsLgScreen, useIsXlScreen } from 'src/hooks'
import { getJob } from 'src/ducks/selectors'
import {
  JobItem,
  Contact,
  COMMUNICATION_METHODS,
  JobProperties,
} from 'src/ducks/types'
import { ContentBox, Divider, TabBoxMargin, Button } from '../../Templates'
import { PrivateLoader } from 'src/components/templates'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { ModalActionType } from 'src/components/UI/CustomUI/molecules/Modal/types'
import styles from './styles.module.scss'
import { isEmpty } from 'lodash'
import {
  validateEmail,
  CONTACT_ROLE_TEXT,
  CONTACT_ROLE,
  PHONE_MASK_INPUT,
  PHONE_MASK_REGEXP,
} from 'src/helpers'
import { jobActions } from 'src/ducks/actions'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button as ButtonDeleteModal, Checkbox, FormControlLabel } from '@mui/material'
import { UpdateRequest } from '~api/types'
import ToastMessage from 'src/components/UI/CustomUI/molecules/Toast'

const newContactDefault: Contact = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  otherRole: '',
  role: null,
  approvalType: null,
  approvedOn: null,
  isScheduler: false,
  isRequester: false,
  fullName: '',
  sendEstimate: false,
  isPayer: false,
  preferredCommunicationMethods: []
}

const getChangedContactFields = (
  contact: Contact,
  newContact: Contact
) => {
  const edited = { ...newContact }
  for (const attr in newContact) {
    if (
      contact &&
      newContact[attr as keyof Contact] ===
      contact[attr as keyof Contact]
    ) {
      delete edited[attr as keyof Contact]
    }
  }

  return edited
}

const JobContact = () => {
  const dispatch = useDispatch()
  const job = useSelector(getJob())
  const { properties } = job as JobItem
  const { contacts } = properties

  const [open, setOpen] = useState(false)
  const [newContact, setNewContact] = useState(newContactDefault)
  const [showWarnings, setShowWarnings] = useState(false)
  const [loading, setLoading] = useState(false)
  const [xlScreen] = useIsXlScreen()
  const [lgScreen] = useIsLgScreen()
  const { firstName, lastName, email, phone, preferredCommunicationMethods } = newContact
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const validate =
    !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    !isEmpty(email) &&
    validateEmail(email) &&
    ((PHONE_MASK_REGEXP.test(phone) && !isEmpty(phone)) || isEmpty(phone))

  const handleClose = () => {
    setOpen(false)
    setShowWarnings(false)
    setNewContact(newContactDefault)
  }

  const handleCloseDelete = () => {
    setOpenDeleteModal(false)
    setShowWarnings(false)
    setNewContact(newContactDefault)
  }

  const handleUpdateJobContact = (
    contact: Partial<Contact>,
    index: number
  ) => {
    setLoading(true)
    dispatch(
      jobActions.updateJobContact(
        {
          contact: contact,
          idx: index,
        },
        (succ: boolean) => {
          setLoading(false)
          if (succ) {
            toast.success.call('success', 'Job Contact updated successfully')
            setNewContact(newContact)
          }
        }
      )
    )
  }

  const handleUpdateJobProperty = (payload: Partial<JobProperties>) => {
    setLoading(true)

    dispatch(
      jobActions.updateJobProperty(payload, (succ) => {
        setLoading(false)
      })
    )
  }

  const handleRemoveContact = () => {
    setOpenDeleteModal(false)
    setLoading(true)

    const indexRemove = contacts.findIndex((c) => c.id === newContact.id)
    if (newContact.id) {
      dispatch(
        jobActions.removeJobContact(
          {
            idx: indexRemove,
          },
          (succ: boolean) => {
            setLoading(false)
            if (succ) {
              toast.success.call('success', <ToastMessage title='Job Contact' subtitle='Job Contact removed successfully'></ToastMessage>, { autoClose: false, progress: .99 })
            }
          }
        )
      )
    }
  }


  const handleAdd = () => {
    const roleKey = roleOptions.find((r) => r.key === newContact.role)?.key
    let contactToEdit = { ...newContact };
    contactToEdit.fullName = `${firstName} ${lastName}`
    /*  if (contactToEdit.otherRole === null) {
       contactToEdit.otherRole = ''
     } */
    if (roleOptions.some((r) => r.key === contactToEdit.role) && roleKey) {
      contactToEdit.role = roleKey
    }/*  else {
      contactToEdit.role = roleOptions[0]?.key
    } */

    setNewContact(contactToEdit)

    if (!isEdit) {
      setLoading(true)
      setNewContact(newContactDefault)

      dispatch(
        jobActions.createJobContact(newContact, (succ: boolean) => {
          setLoading(false)
          if (succ) {
            setOpen(false)
            toast.success.call('success', 'Job Contact created successfully')
            setNewContact(newContactDefault)
          }
        })
      )
    }
    const contact = contacts.find((c) => c.id === newContact.id)

    if (isEdit && contact) {
      const diff = getChangedContactFields(contact, contactToEdit)
      if (!isEmpty(diff)) {
        setLoading(true)
        handleUpdateJobContact(
          diff,
          contacts.findIndex((c) => c.id === contact.id)
        )
        setOpen(false)
      }
    }
  }

  const roleOptions = [
    { key: CONTACT_ROLE.LISTING_AGENT, label: CONTACT_ROLE_TEXT.LISTING_AGENT },
    { key: CONTACT_ROLE.BUYERS_AGENT, label: CONTACT_ROLE_TEXT.BUYERS_AGENT },
    {
      key: CONTACT_ROLE.BUYERS_CLOSING_COORDINATOR,
      label: CONTACT_ROLE_TEXT.BUYERS_CLOSING_COORDINATOR,
    },
    {
      key: CONTACT_ROLE.SELLERS_CLOSING_COORDINATOR,
      label: CONTACT_ROLE_TEXT.SELLERS_CLOSING_COORDINATOR,
    },
    { key: CONTACT_ROLE.HOMEOWNER, label: CONTACT_ROLE_TEXT.HOMEOWNER },
    { key: CONTACT_ROLE.HOME_BUYER, label: CONTACT_ROLE_TEXT.HOME_BUYER },
    { key: CONTACT_ROLE.INSPECTOR, label: CONTACT_ROLE_TEXT.INSPECTOR },
    { key: CONTACT_ROLE.OTHER, label: CONTACT_ROLE_TEXT.OTHER },
  ]

  const handleActive = (index: number, flag: string) => {
    let contactsUpdate = contacts

    const request: UpdateRequest = []

    // request.push({
    //   op: 'replace',
    //   path: `/properties/contacts/${idx}/${attr}`,
    //   value: contact[attr as keyof Contact]
    // })

    switch (flag) {
      case 'Estimate':
        request.push({ op: 'replace', path: `/properties/contacts/${index}/sendEstimate`, value: !contacts[index].sendEstimate })
        break
      case 'Requester':
        request.push({ op: 'replace', path: `/properties/contacts/${index}/isRequester`, value: !contacts[index].isRequester })
        break
      case 'Scheduler':
        request.push({ op: 'replace', path: `/properties/contacts/${index}/isScheduler`, value: !contacts[index].isScheduler })
        break
      case 'Payer':
        //contactsUpdate[index].isPayer = !contacts[index].isPayer
        const payerIndex = contactsUpdate.findIndex((p, index) => p.isPayer === true)

        if (payerIndex < 0) {
          request.push({ op: 'replace', path: `/properties/contacts/${index}/isPayer`, value: true })
        } else {
          if (contactsUpdate[index].isPayer === true) {
            request.push({ op: 'replace', path: `/properties/contacts/${index}/isPayer`, value: false })
          } else {
            request.push({ op: 'replace', path: `/properties/contacts/${index}/isPayer`, value: true })
            request.push({ op: 'replace', path: `/properties/contacts/${payerIndex}/isPayer`, value: false })
          }
        }
        break

      default:
        break
    }

    setLoading(true)
    dispatch(
      jobActions.updateJobByPath(request, (succ) => {
        setLoading(false)
      })
    )
  }

  const modalAction: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'outlined',
      onClick: () => {
        handleClose()
      },
    },
    {
      textButton: 'Update Contact',
      variant: 'contained',
      onClick: () => {
        if (validate) {
          handleAdd()
        } else {
          setShowWarnings(true)
        }
      },
      icon: 'Save',
      iconColor: 'white',
    },
  ]

  const handlePhone = (e: any) => {
    setNewContact({ ...newContact, phone: e.target.value })
  }

  const handleRole = (role: any) => {
    if (role !== undefined) {
      setNewContact({ ...newContact, role: role.key })
    }
  }

  return (
    <>
      <TabBoxMargin>
        <PrivateLoader loading={loading}>
          {contacts.map((contact, index) => {
            const {
              fullName,
              role,
              phone,
              email,
              sendEstimate,
              isRequester,
              isScheduler,
              isPayer,
              preferredCommunicationMethods,
            } = contact

            return (
              <>
                <ContentBox
                  key={index}
                  gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}
                >
                  <LabeledText labelValue={fullName} textValue={`(${role})`} />
                  <LabeledText labelValue="" textValue={undefined} />
                </ContentBox>
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Box className="contactItem">
                      <LabeledText labelValue="Preferred contact by:" />
                    </Box>
                    <Box display="flex" className={styles.contactIconItem}>
                      <Box className={styles.contactIconItem}>
                        <icons.Phone
                          fontSize="small"
                          color={
                            preferredCommunicationMethods?.includes(
                              COMMUNICATION_METHODS.PHONE
                            )
                              ? 'infoText'
                              : 'action'
                          }
                          className={styles.icon}
                        />
                      </Box>
                      <Box>
                        <Typography>Phone</Typography>
                      </Box>
                    </Box>
                    <Box display="flex" className={styles.contactIconItem}>
                      <Box className={styles.contactIcon}>
                        <icons.Email
                          fontSize="small"
                          color={
                            preferredCommunicationMethods?.includes(
                              COMMUNICATION_METHODS.EMAIL
                            )
                              ? 'infoText'
                              : 'action'
                          }
                          className={styles.icon}
                        />
                      </Box>
                      <Box>
                        <Typography className={styles.contactIcon}>
                          Email
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" className={styles.contactIconItem}>
                      <Box className={styles.contactIcon}>
                        <icons.PhoneAndroid
                          fontSize="small"
                          color={
                            preferredCommunicationMethods?.includes(
                              COMMUNICATION_METHODS.TEXT
                            )
                              ? 'infoText'
                              : 'action'
                          }
                          className={styles.icon}
                        />
                      </Box>
                      <Box>
                        <Typography>Text</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <ContentBox
                  key={index}
                  gridTemplateColumns={xlScreen || lgScreen ? '1fr 1fr' : '1fr'}
                >
                  <LabeledText labelValue="Phone:" textValue={phone} />
                  <LabeledText labelValue="Mail:" textValue={email} />
                </ContentBox>
                <Box
                  gap={0.5}
                  display={xlScreen || lgScreen ? 'flex' : 'grid'}
                  gridTemplateColumns="1fr 1fr"
                >
                  <Button
                    isActive={sendEstimate}
                    onToggle={() => {
                      handleActive(index, 'Estimate')
                    }}
                    sx={!(xlScreen || lgScreen) ? { height: '34px' } : {}}
                  >
                    Send Estimate
                  </Button>
                  <Button
                    isActive={isRequester}
                    onToggle={() => {
                      handleActive(index, 'Requester')
                    }}
                    sx={!(xlScreen || lgScreen) ? { height: '34px' } : {}}
                  >
                    Requester
                  </Button>
                  <Button
                    isActive={isScheduler}
                    onToggle={() => {
                      handleActive(index, 'Scheduler')
                    }}
                    sx={!(xlScreen || lgScreen) ? { height: '32px' } : {}}
                  >
                    Scheduler
                  </Button>
                  <Button
                    isActive={isPayer}
                    onToggle={() => {
                      handleActive(index, 'Payer')
                    }}
                    sx={!(xlScreen || lgScreen) ? { height: '32px' } : {}}
                  >
                    Payer
                  </Button>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setIsEdit(true)
                      setNewContact({
                        firstName: contact.firstName,
                        lastName: contact.lastName,
                        email: contact.email,
                        phone: contact.phone,
                        otherRole: contact.otherRole,
                        role: contact.role,
                        id: contact.id,
                        approvalType: contact.approvalType,
                        approvedOn: contact.approvedOn,
                        isScheduler: contact.isScheduler,
                        isRequester: contact.isRequester,
                        fullName: contact.fullName,
                        sendEstimate: contact.sendEstimate,
                        isPayer: contact.isPayer,
                        preferredCommunicationMethods: contact.preferredCommunicationMethods
                      })
                      setOpen(true)
                    }}
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    sx={{
                      backgroundColor: 'white',
                      color: '#EB5151',
                      '&:hover': {
                        border: '0',
                        backgroundColor: '#FFCCCC',
                        color: 'EB5151',
                      },
                    }}
                    size="small"
                    onClick={() => {
                      setIsDelete(true)
                      setNewContact({
                        firstName: contact.firstName,
                        lastName: contact.lastName,
                        email: contact.email,
                        phone: contact.phone,
                        otherRole: contact.otherRole,
                        role: contact.role,
                        id: contact.id,
                        approvalType: contact.approvalType,
                        approvedOn: contact.approvedOn,
                        isScheduler: contact.isScheduler,
                        isRequester: contact.isRequester,
                        fullName: contact.fullName,
                        sendEstimate: contact.sendEstimate,
                        isPayer: contact.isPayer,
                      })
                      setOpenDeleteModal(true)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                {index !== contacts.length - 1 && <Divider />}
              </>
            )
          })}
        </PrivateLoader>
      </TabBoxMargin>
      <Box
        sx={{
          position: 'absolute',
          bottom: '12px',
          right: '12px',
        }}
      >
        {open && (
          <Modal
            setOpen={setOpen}
            open={open}
            title={
              isEdit
                ? 'Edit Job Contact Information'
                : 'Let’s add a new Job Contact'
            }
            actions={modalAction}
            onClose={() => {
              handleClose()
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextFieldLabel
                  name="add-job-contact-modal-n"
                  id="add-job-contact-modal-n"
                  type="text"
                  labelVariant="h6"
                  label="First Name:"
                  value={firstName}
                  error={showWarnings && isEmpty(firstName)}
                  className={styles.TextFieldLabel}
                  onChange={(e) => {
                    setNewContact({ ...newContact, firstName: e.target.value })
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel
                  name="add-job-contact-modal-ln"
                  id="add-job-contact-modal-ln"
                  type="text"
                  labelVariant="h6"
                  label="Last Name:"
                  value={lastName}
                  error={showWarnings && isEmpty(lastName)}
                  className={styles.TextFieldLabel}
                  onChange={(e) => {
                    setNewContact(prevNewContact => ({ ...prevNewContact, lastName: e.target.value }))
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel
                  name="add-job-contact-modal-em"
                  id="add-job-contact-modal-em"
                  labelVariant="h6"
                  label="Email:"
                  value={email}
                  disabled={isEdit}
                  error={
                    showWarnings && (isEmpty(email) || !validateEmail(email))
                  }
                  onChange={(e) => {
                    setNewContact({ ...newContact, email: e.target.value })
                  }}
                  className={styles.TextFieldLabel}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel
                  name="add-job-contact-modal-ph"
                  id="add-job-contact-modal-ph"
                  labelVariant="h6"
                  label="Phone:"
                  value={phone}
                  placeholder="876-543-1223"
                  mask={PHONE_MASK_INPUT}
                  error={
                    showWarnings &&
                    !isEmpty(phone) &&
                    !PHONE_MASK_REGEXP.test(phone)
                  }
                  className={styles.TextFieldLabel}
                  onChange={handlePhone}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Preferred method of communication
                </Typography>
                <Grid item container>
                  <Grid>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={preferredCommunicationMethods?.some(method => method === COMMUNICATION_METHODS.PHONE)}
                          onChange={(event) => {
                            setNewContact({ ...newContact, preferredCommunicationMethods: event.target.checked ? [...preferredCommunicationMethods || [], COMMUNICATION_METHODS.PHONE] : newContact.preferredCommunicationMethods?.filter(method => method !== COMMUNICATION_METHODS.PHONE) })
                          }}
                        />
                      }
                      label={<Typography>Phone</Typography>}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={preferredCommunicationMethods?.some(method => method === COMMUNICATION_METHODS.EMAIL)}
                          onChange={(event) => {
                            setNewContact({ ...newContact, preferredCommunicationMethods: event.target.checked ? [...preferredCommunicationMethods || [], COMMUNICATION_METHODS.EMAIL] : newContact.preferredCommunicationMethods?.filter(method => method !== COMMUNICATION_METHODS.EMAIL) })
                          }}
                        />
                      }
                      label={<Typography>Email</Typography>}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={preferredCommunicationMethods?.some(method => method === COMMUNICATION_METHODS.TEXT)}
                          onChange={(event) => {
                            setNewContact({ ...newContact, preferredCommunicationMethods: event.target.checked ? [...preferredCommunicationMethods || [], COMMUNICATION_METHODS.TEXT] : newContact.preferredCommunicationMethods?.filter(method => method !== COMMUNICATION_METHODS.TEXT) })
                          }}
                        />
                      }
                      label={<Typography>Text</Typography>}
                    />
                  </Grid>

                </Grid>

              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  label="Role In This Transaction:"
                  onChange={handleRole}
                  options={roleOptions}
                  value={
                    newContact.role === '' ? roleOptions[0] : newContact.role
                  }
                />
              </Grid>
              {/* <Grid item xs={6}>
                <TextFieldLabel
                  name="add-job-contact-modal-ro"
                  id="add-job-contact-modal-ro"
                  type="text"
                  labelVariant="h6"
                  label="Role - Other"
                  value={newContact.otherRole}
                  className={styles.TextFieldLabel}
                  onChange={(e) => {
                    setNewContact({ ...newContact, otherRole: e.target.value })
                  }}
                />
              </Grid> */}
            </Grid>
          </Modal>
        )}
        <ModalButton
          variant="toggleOutlined"
          sx={{
            backgroundColor: 'white',
            minWidth: '0px',
            height: '30px',
            padding: '0 8px 0 4px',
            marginTop: '10px',
          }}
          onClick={() => {
            setIsEdit(false)
            setOpen(true)
          }}
        >
          <icons.Add sx={{ height: '14px' }} />
          <Typography variant="body2" sx={{ paddingTop: '1px' }}>
            Add New
          </Typography>
        </ModalButton>
      </Box>

      {/* 
      MODAL DELETE

      {open &&
          <Modal
            setOpen={setOpen}
            open={open}
            title={isEdit ? 'Edit Job Contact Information' : 'Let’s add a new Job Contact'}
            actions={modalAction}
            onClose={() => { handleClose() }}
          >

      */}

      <Box
        sx={{
          position: 'absolute',
          bottom: '12px',
          right: '12px',
        }}
      >
        {openDeleteModal && (
          <Modal
            setOpen={setOpenDeleteModal}
            open={openDeleteModal}
            title={'Remove Contact'}
            //actions={modalActionDelete}
            onClose={() => {
              handleCloseDelete()
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  You're going to remove {firstName} {lastName}.{' '}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Are you sure you want want to remove this contact? This action
                  can't be undone.{' '}
                </Typography>
              </Grid>

              <Grid
                item
                xs
                flexDirection="row"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ButtonDeleteModal
                  variant="contained"
                  sx={{
                    width: '100%',
                    height: '40px',
                    backgroundColor: '#FAFBFF',
                    color: '#3A66FF',
                    border: 0,
                    '&:hover': {
                      color: 'white',
                    },
                  }}
                  onClick={handleCloseDelete}
                >
                  Cancel
                </ButtonDeleteModal>
                <ButtonDeleteModal
                  variant="contained"
                  sx={{
                    width: '100%',
                    height: '40px',
                    backgroundColor: '#EB5151',
                    border: 0,
                    '&:hover': {
                      border: '0',
                      backgroundColor: '#EB5151',
                      color: 'white',
                    },
                  }}
                  onClick={handleRemoveContact}
                  endIcon={
                    <icons.Delete
                      style={{
                        width: '12px',
                        height: '12px',
                        padding: '0px !important',
                      }}
                    />
                  }
                >
                  Confirm Remove Contact
                </ButtonDeleteModal>
              </Grid>
            </Grid>
          </Modal>
        )}
        <ModalButton
          variant="toggleOutlined"
          sx={{
            backgroundColor: 'white',
            minWidth: '0px',
            height: '30px',
            padding: '0 8px 0 4px',
            marginTop: '10px',
          }}
          onClick={() => {
            setIsEdit(false)
            setOpen(true)
          }}
        >
          <icons.Add sx={{ height: '14px' }} />
          <Typography variant="body2" sx={{ paddingTop: '1px' }}>
            Add New
          </Typography>
        </ModalButton>
      </Box>
    </>
  )
}

export default JobContact
