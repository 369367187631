/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { errorTextTryingTo, isEmpty, ITEM_STATUS } from '../../helpers'
import { Punchlist } from '../../api'
import {
  CreateItemAction, DeleteBatchAction, DeleteItemAction,
  FetchItemsAction, itemsTypes, UpdateBatchAction, UpdateItemAction, RejectItemAction, RecallItemsAction, MoveItemsBatchAction, RecoverItemAction, UpdateItemTotalsAction, DeleteEstimateItemsAction, UpdateItemByPathAction, CombineItemsAction,
  DownloadCSVAction
} from './types'
import { estimateActions, historyActions, itemsActions, jobActions } from '../actions'
import { UpdateBatchRequest, UpdateRequest, MoveBatchRequest } from '~api/types'
import { getJobId } from '../job/selectors'

import { Item } from '../types'
import { getEstimateId } from '../selectors'

import * as Sentry from '@sentry/react'

export function* fetchItems({ payload, callback }: FetchItemsAction): SagaIterator {
  let success = false
  // yield put(itemsActions.setItems([]))

  try {
    const params = payload.params ?? ''

    const items = yield call(Punchlist.items.getItems, { params })

    if (items) {
      yield put(itemsActions.setItems(items))
    }
    success = true
    if (callback) yield call(callback, success, success ? items.length : 0)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('get the items'))
    if (callback) yield call(callback, false, 0)
  }
}

export function* fetchInspectionItems({ payload, callback }: FetchItemsAction): SagaIterator {
  let success = false
  yield put(itemsActions.setInspectionItems([]))

  try {
    const params = payload.params ?? ''
    const inspectionItems = yield call(Punchlist.items.getInspectionItems, { params })

    if (inspectionItems) {
      yield put(itemsActions.setInspectionItems(inspectionItems))
    }
    success = true
    if (callback) yield call(callback, success, inspectionItems)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('get the inspection items'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchItemsByCategory({ payload, callback }: FetchItemsAction): SagaIterator {
  let success = false
  // yield put(itemsActions.setItemsByCategory([]))

  try {
    const params = payload.params ?? ''
    const groupedItems = yield call(Punchlist.items.getItemsByCategory, { params })

    if (groupedItems) {
      yield put(itemsActions.setItemsByCategory(groupedItems))
    }
    success = true
    if (callback) yield call(callback, success, groupedItems)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('get the items'))
    if (callback) yield call(callback, false)
  }
}

export function* updateItem({ payload, callback }: UpdateItemAction): SagaIterator {
  let success = false
  try {
    const { itemId, partialItem, isEstimate } = payload
    const request: UpdateRequest = []

    const { publicTitle, disclaimer, displayTitle, images, ...rest } = partialItem
    const requestBody = {
      ...rest,
    }

    if (disclaimer && typeof disclaimer !== 'string') {
      requestBody.disclaimer = disclaimer.join('.')
    } else if (disclaimer) {
      requestBody.disclaimer = disclaimer
    }

    delete requestBody.id;
    delete requestBody.estimateId;
    delete requestBody.estimatePublicId;
    delete requestBody.itemId;

    for (const attr in requestBody) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: requestBody[attr as keyof Item]
      })
    }
    const item = yield call(Punchlist.items.updateItem, itemId, request)

    if (!isEmpty(item)) {
      success = true
      if (isEstimate) yield put(estimateActions.fetchEstimate(item.estimateId, (succ) => { }))
      else yield put(jobActions.fetchJob(item.estimateId, (succ) => { }))
    }
    if (callback) yield call(callback, success, item)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('update the item'))
    if (callback) yield call(callback, false)
  }
}

export function* deleteItem({ payload, callback }: DeleteItemAction): SagaIterator {
  let success = false

  try {
    const curJobId = yield select(getJobId)
    const curEstimateId = yield select(getEstimateId())
    const { itemId, selectedOrderId, jobId } = payload

    yield call(Punchlist.items.deleteItem, itemId)
    yield put(itemsActions.fetchItemsByCategory({ params: { jobId: jobId, orderId: selectedOrderId } }))
    yield put(historyActions.fetchHistoryRemovedItems(jobId))

    success = true

    if (curJobId === jobId) yield put(jobActions.fetchJob(curJobId, (succ) => { }))
    if (curEstimateId === jobId) yield put(estimateActions.fetchEstimate(curEstimateId, (succ) => { }))

    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('delete the item'))
    if (callback) yield call(callback, false)
  }
}

export function* updateBatch({ payload, callback }: UpdateBatchAction): SagaIterator {
  let success = false

  try {
    const { itemsIds, partialItem, id } = payload
    const jobId = yield select(getJobId)
    const estimateId = yield select(getEstimateId())
    const request: UpdateBatchRequest = { ids: itemsIds, patch: [] }

    for (const attr in partialItem) {
      request.patch.push({
        op: 'add',
        path: '/' + attr,
        value: partialItem[attr as keyof Item]
      })
    }

    yield call(Punchlist.items.updateBatch, request)
    if (jobId === id) put(jobActions.fetchJob(jobId, (succ) => { }))
    if (estimateId === id) yield put(estimateActions.fetchEstimate(estimateId, (succ) => { }))
    success = true

    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('update the items'))
    if (callback) yield call(callback, false)
  }
}

export function* deleteBatch({ payload, callback }: DeleteBatchAction): SagaIterator {
  let success = false
  const { itemsIds, selectedOrderId, jobId } = payload

  try {

    const curJobId = yield select(getJobId)
    const curEstimateId = yield select(getEstimateId())
    yield call(Punchlist.items.deleteBatch, itemsIds)
    yield put(itemsActions.fetchItemsByCategory({ params: { jobId: jobId, orderId: selectedOrderId } }))
    yield put(historyActions.fetchHistoryRemovedItems(jobId))

    if (curJobId === jobId) yield put(jobActions.fetchJob(curJobId, (succ) => { }))
    if (curEstimateId === jobId) yield put(estimateActions.fetchEstimate(curEstimateId, (succ) => { }))

    success = true

    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('delete the items'))
    if (callback) yield call(callback, false)
  }
}

export function* createItem({ payload, callback }: CreateItemAction): SagaIterator {

  try {
    const { unitMaterialCost, publicTitle, disclaimer, displayTitle, id, quantity, margin, images, totalPrice, isEstimate, orderId, ...rest } = payload

    const request = {
      ...rest,
      itemId: id,
      quantity: Number(quantity),
      status: ITEM_STATUS.NEW,
      totalPrice: Number(totalPrice || 0),
      unitMaterialCost: Number(unitMaterialCost || 0),
      margin: Number(margin || 0),
    }

    if (orderId) request.orderId = orderId

    if (typeof disclaimer === "object") {
      request.disclaimer = disclaimer?.join('.')
    } else {
      request.disclaimer = disclaimer
    }

    const item = yield call(Punchlist.items.createItem, request)

    if (!isEmpty(item)) {
      const { estimateId } = item

      yield put(itemsActions.fetchItemsByCategory({ params: { jobId: estimateId, orderId: request.orderId } }))
      yield put(itemsActions.fetchItems({ params: { jobId: estimateId } }))

      if (isEstimate) yield put(estimateActions.fetchEstimate(estimateId, (succ) => { }))
      else yield put(jobActions.fetchJob(estimateId, (succ) => { }))

      yield call(toast.success, 'The Item was created successfully')
      yield call(callback, true, item)
    }
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('create the item'))
    yield call(callback, false)
  }
}

export function* createItemFromCsv({ payload, callback }: CreateItemAction): SagaIterator {
  try {
    const { id, file } = payload
    yield call(Punchlist.items.createItemsFromCsv, id, file)
    yield put(itemsActions.fetchItemsByCategory({ params: { jobId: id } }))
    yield put(estimateActions.fetchEstimate(id, (succ) => { }))
    yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create items'))
    yield call(callback, false)
  }
}

export function* recallItems({ payload, callback }: RecallItemsAction): SagaIterator {
  try {
    const jobId = yield select(getJobId)
    const estimateId = yield select(getEstimateId())
    const item = yield call(Punchlist.items.recallItems, payload.items)
    if (!isEmpty(item)) {
      if (jobId && jobId === payload.id) yield put(jobActions.fetchJob(jobId, (succ) => { }))
      if (estimateId && estimateId === payload.id) yield put(estimateActions.fetchEstimate(estimateId, (succ) => { }))

      yield call(toast.success, 'The Item was copied successfully')
      yield call(callback, true, item)
    }
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('copying the item'))
    yield call(callback, false, null)
  }
}

export function* rejectItem({ payload, callback }: RejectItemAction): SagaIterator {
  let success = false
  const jobId = yield select(getJobId)
  const estimateId = yield select(getEstimateId())

  try {
    const { itemId, partialItem, id } = payload

    yield call(Punchlist.items.rejectItem, itemId, partialItem)
    success = true

    if (callback) yield call(callback, success)

    if (jobId === id) yield put(jobActions.fetchJob(jobId, (succ) => { }))
    if (estimateId === id) yield put(estimateActions.fetchEstimate(estimateId, (succ) => { }))
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('reject the item'))
    if (callback) yield call(callback, false)
  }
}


export function* moveItems({ payload, callback }: MoveItemsBatchAction): SagaIterator {
  let success = false

  try {
    const { itemsIds, partialItem, selectedOrderId, id } = payload
    const request: MoveBatchRequest = itemsIds

    if (partialItem.orderId) {
      yield call(Punchlist.items.moveItems, partialItem.orderId, request)
      yield put(itemsActions.fetchItemsByCategory({ params: { jobId: id, orderId: selectedOrderId } }))
      success = true
    }

    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('update the items'))
    if (callback) yield call(callback, false)
  }
}

export function* moveItemsToJob({ payload, callback }: MoveItemsBatchAction): SagaIterator {
  let success = false

  try {
    const { itemsIds, partialItem, selectedOrderId } = payload
    const request: MoveBatchRequest = itemsIds
    const jobId = yield select(getJobId)

    yield call(Punchlist.items.moveItemsToJob, partialItem.jobId || '', request)
    yield put(itemsActions.fetchItemsByCategory({ params: { jobId, orderId: selectedOrderId } }))
    yield put(jobActions.fetchJob(jobId, (succ) => { }))
    success = true

    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('move the items to a new job.'))
    if (callback) yield call(callback, false)
  }
}

export function* moveItemsToEstimate({ payload, callback }: MoveItemsBatchAction): SagaIterator {
  let success = false

  try {
    const { itemsIds, partialItem, selectedOrderId } = payload
    const request: MoveBatchRequest = itemsIds
    const estimateId = yield select(getEstimateId())

    yield call(Punchlist.items.moveItemsToEstimate, partialItem.jobId || '', request)
    yield put(itemsActions.fetchItemsByCategory({ params: { jobId: estimateId, orderId: selectedOrderId } }))
    yield put(estimateActions.fetchEstimate(estimateId, (succ) => { }))
    success = true

    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('move the items to a new job.'))
    if (callback) yield call(callback, false)
  }
}

export function* recoverItem({ payload, callback }: RecoverItemAction): SagaIterator {
  try {
    const jobId = yield select(getJobId)
    const estimateId = yield select(getEstimateId())

    const item = yield call(Punchlist.items.recoverItem, payload.item)
    if (!isEmpty(item)) {
      yield call(toast.success, 'The item was recovered successfully')
      yield put(historyActions.fetchHistoryRemovedItems(payload.id || ''))

      if (jobId === payload.id) yield put(jobActions.fetchJob(jobId, (succ) => { }))
      if (estimateId === payload.id) yield put(estimateActions.fetchEstimate(estimateId, (succ) => { }))

      yield call(callback, true, item)
    }
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('recovering the item'))
    yield call(callback, false, null)
  }
}

export function* updateItemTotals({ payload, callback }: UpdateItemTotalsAction): SagaIterator {
  if (isEmpty(payload)) return
  try {
    const { disclaimer, ...rest } = payload
    const requestBody: any = {
      ...rest,
    }
    if (disclaimer && typeof disclaimer !== 'string') {
      requestBody.disclaimer = disclaimer.join('.')
    } else if (disclaimer) {
      requestBody.disclaimer = disclaimer
    }
    const item = yield call(Punchlist.items.updateItemTotals, requestBody)

    if (!isEmpty(item)) {
      if (callback) yield call(callback, true, item)
    }
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('updating the item'))
    if (callback) yield call(callback, false, null)
  }
}

export function* updateItemTotalsSync({ payload, callback }: UpdateItemTotalsAction): SagaIterator {
  try {
    const { itemId, partialItem, isEstimate } = payload
    const request: UpdateRequest = []
    const { publicTitle, disclaimer, displayTitle, images, id, ...rest } = partialItem
    delete partialItem.itemId

    // const { disclaimer, ...rest } = payload
    const requestBody: any = {
      ...rest,
    }
    if (disclaimer && typeof disclaimer !== 'string') {
      requestBody.disclaimer = disclaimer.join('.')
    } else if (disclaimer) {
      requestBody.disclaimer = disclaimer
    }

    for (const attr in requestBody) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: requestBody[attr as keyof Item]
      })
    }

    const item = yield call(Punchlist.items.updateItemSync, itemId, request)



    if (!isEmpty(item)) {
      if (callback) yield call(callback, true, item)
    }
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('updating the item'))
    if (callback) yield call(callback, false, null)
  }
}

export function* deleteEstimateItems({ payload, callback }: DeleteEstimateItemsAction): SagaIterator {
  const estimateId = payload

  try {
    yield call(Punchlist.items.deleteEstimateItems, payload)

    if (estimateId) yield put(estimateActions.fetchEstimate(estimateId, (succ) => { }))
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('deleting items from the estimate'))
    if (callback) yield call(callback, false)
  }
}

export function* updateItemByPath({ payload, callback }: UpdateItemByPathAction): SagaIterator {
  let success = false

  try {
    const { itemId, request } = payload
    const jobId = yield select(getJobId)
    const item = yield call(Punchlist.items.updateItem, itemId, request)


    if (!isEmpty(item)) {
      // yield put(jobActions.fetchJob(jobId))
      // yield put(itemsActions.fetchItemsByCategory({ params: { jobId } }))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('update the job item'))
    if (callback) yield call(callback, false)
  }
}

export function* combineItems({ payload, callback }: CombineItemsAction): SagaIterator {
  let succ = false

  try {
    const { id, request, isEstimate } = payload
    const jobId = yield select(getJobId)
    const estimateId = yield select(getEstimateId())

    const item = yield call(Punchlist.items.mergeItems, id, request)

    if (!isEmpty(item)) {
      yield put(itemsActions.fetchItemsByCategory({ params: { jobId: isEstimate ? estimateId : jobId } }))
      succ = true
    }

    if (callback) yield call(callback, succ)
  } catch (error) {
    Sentry.captureException(error);

    yield call(toast.error, errorTextTryingTo('combine items'))
    if (callback) yield call(callback, false)
  }
}

export function* downloadItemsCSV({ payload, callback }: DownloadCSVAction): SagaIterator {
  const { id, type, orderId } = payload

  try {
    const items = yield call(Punchlist.items.downloadItemsCSV, id, type, orderId)

    yield call(callback, true, items)

  } catch (error) {
    yield call(toast.error, errorTextTryingTo('download csv'))
  }
}


export default function* saga(): SagaIterator {
  yield takeLatest(itemsTypes.FETCH_ITEMS, fetchItems)
  yield takeLatest(itemsTypes.FETCH_INSPECTION_ITEMS, fetchInspectionItems)
  yield takeLatest(itemsTypes.FETCH_ITEMS_BY_CATEGORY, fetchItemsByCategory)
  yield takeLatest(itemsTypes.UPDATE_ITEM, updateItem)
  yield takeLatest(itemsTypes.UPDATE_ITEM_BY_PATH, updateItemByPath)
  yield takeLatest(itemsTypes.DELETE_ITEM, deleteItem)
  yield takeLatest(itemsTypes.UPDATE_BATCH, updateBatch)
  yield takeLatest(itemsTypes.DELETE_BATCH, deleteBatch)
  yield takeLatest(itemsTypes.CREATE_ITEM, createItem)
  yield takeLatest(itemsTypes.REJECT_ITEM, rejectItem)
  yield takeLatest(itemsTypes.RECALL_ITEM, recallItems)
  yield takeLatest(itemsTypes.MOVE_ITEMS, moveItems)
  yield takeLatest(itemsTypes.MOVE_ITEMS_TO_JOB, moveItemsToJob)
  yield takeLatest(itemsTypes.MOVE_ITEMS_TO_ESTIMATE, moveItemsToEstimate)
  yield takeLatest(itemsTypes.RECOVER_ITEM, recoverItem)
  yield takeLatest(itemsTypes.CREATE_ITEM_FROM_CSV, createItemFromCsv)
  yield takeLatest(itemsTypes.UPDATE_ITEM_TOTALS, updateItemTotals)
  yield takeLatest(itemsTypes.UPDATE_ITEM_TOTALS_SYNC, updateItemTotalsSync)
  yield takeLatest(itemsTypes.DELETE_ESTIMATE_ITEMS, deleteEstimateItems)
  yield takeLatest(itemsTypes.COMBINE_ITEMS, combineItems)
  yield takeLatest(itemsTypes.DOWNLOAD_ITEMS_CSV, downloadItemsCSV)
}
