import { UpdateRequest } from '~api/types'
import { GroupedItem, Item } from '../types'
import {
  ItemsActions, itemsTypes, ItemsParams, UpdateItemPayload,
  UpdateBatchPayload, RejectBatchPayload, DeleteItemPayload, DeleteBatchPayload
} from './types'

const actions = {
  fetchItems: (
    payload: {
      params?: Partial<ItemsParams>,
    },
    callback?: (succ: boolean, count?: number) => void): ItemsActions => ({
      type: itemsTypes.FETCH_ITEMS,
      payload,
      callback
    }),
  fetchItemsByCategory: (
    payload: {
      params?: Partial<ItemsParams>,
    },
    callback?: (succ: boolean, items?: Item) => void): ItemsActions => ({
      type: itemsTypes.FETCH_ITEMS_BY_CATEGORY,
      payload,
      callback
    }),
  fetchInspectionItems: (
    payload: {
      params?: Partial<ItemsParams>,
    },
    callback?: (succ: boolean, items?: Item) => void): ItemsActions => ({
      type: itemsTypes.FETCH_INSPECTION_ITEMS,
      payload,
      callback
    }),
  setItems: (payload: Item[]): ItemsActions => ({
    type: itemsTypes.SET_ITEMS,
    payload
  }),
  setInspectionItems: (payload: Item[]): ItemsActions => ({
    type: itemsTypes.SET_INSPECTION_ITEMS,
    payload
  }),
  setItemsByCategory: (payload: GroupedItem[]): ItemsActions => {
    return {
      type: itemsTypes.SET_ITEMS_BY_CATEGORY,
      payload
    }
  },
  updateItemByPath: (
    payload: any,
    callback?: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.UPDATE_ITEM_BY_PATH,
    payload,
    callback
  }),
  updateItem: (
    payload: UpdateItemPayload,
    callback?: (succ: boolean, newItem?: Item) => void
  ): ItemsActions => ({
    type: itemsTypes.UPDATE_ITEM,
    payload,
    callback
  }),
  deleteItem: (
    payload: DeleteItemPayload,
    callback?: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.DELETE_ITEM,
    payload,
    callback
  }),
  deleteEstimateItems: (
    payload: string,
    callback?: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.DELETE_ESTIMATE_ITEMS,
    payload,
    callback
  }),
  updateBatch: (
    payload: UpdateBatchPayload,
    callback?: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.UPDATE_BATCH,
    payload,
    callback
  }),
  deleteBatch: (
    payload: DeleteBatchPayload,
    callback?: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.DELETE_BATCH,
    payload,
    callback
  }),
  createItem: (
    //TODO: replace with CreateItemRequest
    payload: any,
    callback: (succ: boolean, item?: Item) => void
  ): ItemsActions => ({
    type: itemsTypes.CREATE_ITEM,
    payload,
    callback
  }),
  createItemFromCsv: (
    payload: any,
    callback: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.CREATE_ITEM_FROM_CSV,
    payload,
    callback
  }),
  rejectItem: (
    payload: RejectBatchPayload,
    callback?: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.REJECT_ITEM,
    payload,
    callback
  }),
  recallItems: (
    payload: {
      items: Item[]
      id: string
    },
    callback: (succ: boolean, item: Item | null) => void
  ): ItemsActions => ({
    type: itemsTypes.RECALL_ITEM,
    payload,
    callback
  }),

  moveItemBatch: (
    payload: UpdateBatchPayload,
    callback?: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.MOVE_ITEMS,
    payload,
    callback
  }),

  moveItemtoJobBatch: (
    payload: UpdateBatchPayload,
    callback?: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.MOVE_ITEMS_TO_JOB,
    payload,
    callback
  }),

  moveItemtoEstimateBatch: (
    payload: UpdateBatchPayload,
    callback?: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.MOVE_ITEMS_TO_ESTIMATE,
    payload,
    callback
  }),

  recoverItem: (
    payload: {
      item: Item,
      id?: string
    },
    callback: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.RECOVER_ITEM,
    payload,
    callback
  }),

  updateItemTotals: (
    payload: Partial<Item>,
    callback: (succ: boolean, item: Item | null) => void
  ): ItemsActions => ({
    type: itemsTypes.UPDATE_ITEM_TOTALS,
    payload,
    callback
  }),

  updateItemTotalsSync: (
    payload: UpdateItemPayload,
    callback: (succ: boolean, item: Item | null) => void
  ): ItemsActions => ({
    type: itemsTypes.UPDATE_ITEM_TOTALS_SYNC,
    payload,
    callback
  }),

  combineItems: (
    payload: {
      id: string
      request: string[]
      isEstimate?: boolean
    },
    callback: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.COMBINE_ITEMS,
    payload,
    callback,
  }),

  fetchRecommendedItems: (
    payload: string,
    callback?: (succ: boolean) => void
  ): ItemsActions => ({
    type: itemsTypes.FETCH_RECOMMENDED_ITEMS,
    payload,
    callback
  }),
  setRecommendedItems: (payload: Partial<Item>[]): ItemsActions => ({
    type: itemsTypes.SET_RECOMMENDED_ITEMS,
    payload
  }),
  downloadItemsCSV: (
    payload: {
      id: string, 
      type: 'estimates' | 'jobs',
      orderId?: string
    },
    callback: (succ: boolean, items: any) => void,
  ): ItemsActions => ({
    type: itemsTypes.DOWNLOAD_ITEMS_CSV,
    payload,
    callback
  }),
}

export default actions
