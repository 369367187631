import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'
import { UpdateRequest } from '~api/types'
import { EstimateItem } from 'src/ducks/types'
import { SearchParams } from 'src/ducks/searches/types'

const base = '/estimates'

const apiController: ApiController = {
  getEstimates: async ({ start, limit, searchParams }) => {
    const params = formatParams(searchParams)
    return await axios
      .get(`${base}?${params}`, {
        params: {
          start,
          limit,
          sortBy: (searchParams as SearchParams)?.sortBy ?? 'createdOn',
          sortOrder: (searchParams as SearchParams)?.sortOrder ?? 'DESC',
        },
      })
      .then((response) => response.data)
  },

  getRelatedEstimates: async ({ id }) => {
    return await axios
      .get(`${base}/${id}/related`)
      .then((response) => response.data)
  },

  getEstimatesCounts: async ({ searchParams }) => {
    const params = formatParams(searchParams)
    return await axios

      .get(`${base}/counts?${params}`)
      .then((response) => response.data)
  },

  getEstimateById: async (estimateId: string) =>
    await axios.get(`${base}/${estimateId}`).then((response) => response.data),

  updateEstimate: async (estiamte: string, request) => {
    return await axios
      .patch(`${base}/${estiamte}`, request)
      .then((response) => response.data)
  },

  updateEstimateItem: async (estimateId: string, itemId: string, request) =>
    await axios
      .patch(`${base}/${estimateId}/items/${itemId}`, request)
      .then((response) => response.data),

  updateEstimateItems: async (estimateId: string, items) => {
    return await axios
      .put(`${base}/${estimateId}/items`, items)
      .then((response) => response.data)
  },

  getEstimateItems: async (estimateId: string) =>
    await axios
      .get(`${base}/${estimateId}/items`)
      .then((response) => response.data),

  updateEstimateContact: async (estimateId: string, request) => {
    return await axios
      .patch(`${base}/${estimateId}`, request)
      .then((response) => response.data)
  },

  patchEstimate: async (estimateId: string, request: UpdateRequest) =>
    await axios
      .patch(`${base}/${estimateId}`, request)
      .then((response) => response.data),

  cloneEstimate: async (id: string, shallow?: boolean) =>
    await axios
      .post(
        `${base}/${id}/clone?shallow=${shallow === undefined ? true : shallow}`
      )
      .then((response) => response.data),

  submitEstimate: async (estimatorId: string, payload: any) => {
    return axios
      .post(`${base}/${estimatorId}/submission`, payload)
      .then((response) => response.data)
  },

  createEstimate: async (request: Partial<EstimateItem>) =>
    await axios.post(`${base}`, request).then((response) => response.data),

  createEstimateItem: async (estimateId: string, request) => {
    return await axios
      .post(`${base}/${estimateId}/items`, request)
      .then((response) => response.data)
  },

  share: async (id: string, request) =>
    await axios
      .post(`${base}/${id}/share`, request)
      .then((response) => response.data),

  releaseEstimate: async (estimateId: string, request) =>
    await axios
      .post(`${base}/${estimateId}/release`, request)
      .then((response) => response.data),

  ocrEstimate: async (estimateId: string) =>
    await axios
      .post(`${base}/${estimateId}/ocr`)
      .then((response) => response.data),
}

export default apiController
